import { computed } from 'nanostores';
import { persistentAtom } from '@nanostores/persistent';

interface ISkilljarUser { 
  email: string;
  firstName?: string;
  id: string;
  lastName?: string; 
  name: string;
}

const initialUserData: ISkilljarUser = {
  email: '',
  firstName: undefined,
  id: '',
  lastName: undefined,
  name: '',
};

export const $skilljarUser = persistentAtom<ISkilljarUser>('skilljarUser', initialUserData, {
  encode: JSON.stringify,
  decode: JSON.parse,
});

export const $loggedIn = computed($skilljarUser, (user) => (
  // Check if the user object has a valid id to determine if the user is logged in
  // This assumes that a valid user will always have an id
  !!user && !!user.id
));
